import { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { track } from '../../../lib/tracker';
import { VideoFragment } from '../../../wp-generated/types';
import Button from '../../Button';
import { moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.section<{ $background: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 var(--site-padding) var(--spacing-x-large);
  margin: var(--section-padding) 0 0;
  z-index: 1;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: auto;
    width: 100vw;
    height: calc(min(92vw, 675px) * 0.5625 * 0.8 + var(--spacing-large));
    clip-path: ellipse(100% 100% at 50% 100%);
    background: ${({ $background }) => $background};
    z-index: -1;
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-large);
  text-align: center;
`;

const VideoContainer = styled.div`
  position: relative;
  border: var(--spacing-medium) solid var(--color-deep-purple);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  max-width: calc(640px + 2 * var(--spacing-medium));
  max-height: calc(360px + 2 * var(--spacing-medium));
  overflow: hidden;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  max-width: 640px;
  max-height: 360px;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  cursor: pointer;
  filter: blur(0);
  transform: scale(1.1);
`;

const PlayButton = styled(Button)`
  padding: 0;
  font-size: 17px;
  width: fit-content;
  position: absolute;
  padding: 20px var(--spacing-medium);
  bottom: clamp(var(--spacing-medium), 15%, var(--spacing-large));
`;

const StyledVideo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    max-width: 640px;
    max-height: 360px;
    aspect-ratio: 16 / 9;

    iFrame {
      display: flex;
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: 16 / 9;
    }
  }
`;

interface thumbnailData {
  title: string | null;
  author_name: string | null;
  author_url: string | null;
  type: string | null;
  height: number | null;
  width: number | null;
  version: string | null;
  provider_name: string | null;
  provider_url: string | null;
  thumbnail_height: number | null;
  thumbnail_width: number | null;
  thumbnail_url: string | null;
}

const parseOEmbed = (htmlString: string) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  const iframe = div.querySelector('iframe');
  const oEmbedData = iframe?.getAttribute('data-oembed');
  return oEmbedData ? JSON.parse(oEmbedData) : null;
};

const Video = ({ moduleData }: { moduleData: VideoFragment }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const [oEmbedData, setOEmbedData] = useState<thumbnailData | null | undefined>(null);
  const id = useId();
  const hasTrackedPlay = useRef(false);

  useEffect(() => {
    const thumbnail: thumbnailData | null | undefined = parseOEmbed(moduleData.code || '');
    setOEmbedData(thumbnail);
  }, [moduleData]);

  useEffect(() => {
    if (!document.getElementById('youtube_iframe_api')) {
      const tag = document.createElement('script');
      tag.id = 'youtube_iframe_api';
      tag.src = 'https://www.youtube.com/iframe_api';

      document.body.appendChild(tag);
    }
    if (!playVideo) return;

    let clearProgressInterval = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

    const createPlayer = () => {
      const player = new (window as any).YT.Player(id, {
        events: {
          onStateChange: (e: { data: number }) => {
            if (e.data === 0) {
              track({
                event: 'video_complete',
                traits: {
                  event_info: {
                    video_id: player.playerInfo?.videoData?.video_id,
                    video_length: player.getDuration(),
                    video_title: moduleData.title ?? '',
                  },
                },
              });
            }
            if (hasTrackedPlay.current || e.data !== 1) return;

            let lastProgressPercentage = 0;

            const progressInterval = setInterval(() => {
              const progressPercentage =
                (100 * (player?.getCurrentTime() ?? 0)) / (player?.getDuration() ?? 1);
              const triggerPercentages = [90, 75, 50, 25];
              const triggeredPercentage = triggerPercentages.find(
                percentage =>
                  progressPercentage > percentage && lastProgressPercentage <= percentage,
              );
              if (triggeredPercentage) {
                track({
                  event: 'video_progress',
                  traits: {
                    event_info: {
                      video_id: player.playerInfo?.videoData?.video_id,
                      video_length: player.getDuration(),
                      progress_percent: triggeredPercentage,
                      video_title: moduleData.title ?? '',
                    },
                  },
                });
              }
              lastProgressPercentage = progressPercentage;
            }, 500);

            clearProgressInterval = () => window.clearInterval(progressInterval);
            hasTrackedPlay.current = true;
            track({
              event: 'video_start',
              traits: {
                event_info: {
                  video_id: player.playerInfo?.videoData?.video_id,
                  video_length: player.getDuration(),
                  video_title: moduleData.title ?? '',
                },
              },
            });
          },
        },
      });
    };

    if ((window as any).YT) {
      createPlayer();
    } else {
      (window as any).onYouTubeIframeAPIReady = createPlayer;
    }
    return clearProgressInterval;
  }, [moduleData.title, id, playVideo]);

  const { t } = useTranslation();

  return (
    <Container $background={moduleBackgroundToCSS(moduleData.bgColor)}>
      <Title>{moduleData.title}</Title>
      <VideoContainer>
        {playVideo ? (
          <StyledVideo
            // Not using lazy loading and therefore replacing data-src with src.
            // Also added autoplay and mute to src-url so video plays directly when the play-button is clicked.
            dangerouslySetInnerHTML={{
              __html:
                moduleData.code
                  ?.replace('data-src', 'src')
                  .replace('<iframe ', `<iframe id="${id}" `)
                  .replace('?', `?autoplay=1&mute=1&origin=${window.location.origin}&`) || '',
            }}
          />
        ) : (
          <ThumbnailContainer>
            <Thumbnail
              src={
                oEmbedData?.thumbnail_url ||
                'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
              }
              onClick={() => {
                setPlayVideo(true);
              }}
              alt=""
            />
            <PlayButton
              variant="primary"
              onClick={() => {
                setPlayVideo(true);
              }}
            >
              {t('marketing_play_video')}
            </PlayButton>
          </ThumbnailContainer>
        )}
      </VideoContainer>
    </Container>
  );
};

export default Video;
