'use client';

import { Honeybadger } from '@honeybadger-io/react';
import { useEffect, useRef } from 'react';

export const MissingModule = ({ typename }: { typename: string }) => {
  const firedRef = useRef<string | null>(null);
  useEffect(() => {
    if (firedRef.current === typename) return;

    Honeybadger.notify(`Missing module implementation for ${typename} at ${window.location.href}`);
    firedRef.current = typename;
  }, [typename]);
  return null;
};
