import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FaqFragment } from '../../../wp-generated/types';
import { moduleBackgroundToCSS } from '../getModuleColors';

const FaqContainer = styled.section<{ $background: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--section-padding) var(--site-padding);
  background: ${({ $background }) => $background};
`;

const FaqTitle = styled.h2`
  max-width: 800px;
  font-weight: var(--font-weight-extra-bold);
  text-transform: uppercase;
  margin-bottom: var(--spacing-large);
`;

const Faqs = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
`;

const FaqItem = styled.li<{ $first: boolean }>`
  list-style: none;

  ${({ $first }) =>
    $first &&
    css`
      &:before {
        background: var(--text-color);
        content: '';
        display: block;
        height: 1px;
        opacity: 0.2;
        width: 100%;
      }
    `}

  &:after {
    background: var(--text-color);
    content: '';
    display: block;
    height: 1px;
    opacity: 0.2;
    width: 100%;
  }
`;

const QuestionAndChevron = styled.button<{ $background: string }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 100%;
  background: ${({ $background }) => $background};
`;

const Question = styled.h3`
  margin: 0;
  padding: var(--spacing-medium) var(--spacing-small);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-large);
  line-height: 20px;
`;

const rotateStyling = css`
  svg {
    transform: rotate(-180deg);
  }
`;

const ChevronDown = styled.div<{ $open?: boolean }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  > svg {
    width: 17px;
    height: 17px;
    transition-duration: var(--anim-speed);
  }

  ${({ $open }) => $open && rotateStyling};

  @media (min-width: 768px) {
    > svg {
      width: 25px;
      height: 25px;
    }
  }
`;

const Answer = styled.section<
  { $open: boolean; $maxHeight?: number } & React.HTMLAttributes<HTMLDivElement>
>`
  box-sizing: border-box;
  margin: 0 var(--spacing-small);
  max-height: ${({ $open, $maxHeight }) => ($open ? $maxHeight + 'px' : 0)};
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  transition:
    max-height var(--anim-speed) var(--easing),
    opacity var(--anim-speed) var(--easing),
    pointer-events var(--anim-speed) var(--easing);

  ${({ $open }) =>
    $open &&
    css`
      display: block;
      opacity: 1;
      pointer-events: auto;
    `};
`;

const AnswerText = styled.div`
  margin: 0 var(--spacing-small) var(--spacing-large);
`;

const FaqItemComponent = ({
  $faq,
  $index,
  $background,
}: {
  $faq: NonNullable<FaqFragment['faqs']>[0];
  $index: number;
  $background: string;
}) => {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    $faq?.title && (
      <FaqItem key={$index} $first={$index === 0}>
        <QuestionAndChevron
          $background={$background}
          onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
          aria-expanded={isOpen}
          aria-controls={`faq-q&a-${$index}`}
          aria-labelledby={`faq-question-${$index}`}
        >
          <Question id={`faq-question-${$index}`}>{$faq.title || ''}</Question>
          <ChevronDown $open={isOpen}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1000 1000"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="currentColor"
                d="M18 238C38 218 72 218 92 238 92 238 92 238 92 238L500 650 908 238C928 218 962 218 982 238 1002 258 1002 292 982 312L538 762C518 782 484 782 464 762 464 762 464 762 464 762L18 316C-4 296-6 264 14 242 14 240 16 240 18 238Z"
              />
            </svg>
          </ChevronDown>
        </QuestionAndChevron>
        <Answer
          $open={isOpen}
          $maxHeight={contentContainerRef.current?.scrollHeight}
          ref={contentContainerRef}
          id={`faq-q&a-${$index}`}
          tabIndex={0}
          role="region"
          aria-labelledby={`faq-question-${$index}`}
        >
          <AnswerText
            dangerouslySetInnerHTML={{
              __html: $faq?.description || '',
            }}
          />
        </Answer>
      </FaqItem>
    )
  );
};

const Faq = ({ moduleData }: { moduleData: FaqFragment }) => {
  return (
    <FaqContainer $background={moduleBackgroundToCSS(moduleData.bgColor)}>
      <FaqTitle>{moduleData.title}</FaqTitle>
      <Faqs>
        {moduleData.faqs?.map((faq, index) => (
          <FaqItemComponent
            key={index}
            $faq={faq}
            $index={index}
            $background={moduleBackgroundToCSS(moduleData.bgColor)}
          />
        ))}
      </Faqs>
    </FaqContainer>
  );
};

export default Faq;
