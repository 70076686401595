import { PageFragment } from '../../../wp-generated/types';
import Hero from './Hero';
import HeroAi from './HeroAi';
import HeroBg from './HeroBg';
import HeroCenter from './HeroCenter';
import HeroForm from './HeroForm';
import HeroTrial from './HeroTrial';

const HeroSelector = ({ hero, pageName }: { hero: PageFragment['hero']; pageName: string }) => {
  if (hero && hero.heroTemplate) {
    for (const template of hero.heroTemplate) {
      switch (template) {
        case 'hero':
          return <Hero data={hero.hero} />;
        case 'hero-center':
          return <HeroCenter data={hero.heroCenter} />;
        case 'hero-form':
          return <HeroForm data={hero.heroForm} formTrackingName={pageName} />;
        case 'hero-trial':
          return <HeroTrial data={hero.heroTrial} />;
        case 'hero-ai':
          return <HeroAi data={hero.heroAi} />;
        case 'hero-bg':
          return <HeroBg data={hero.heroBg} />;
        default:
          continue;
      }
    }
  }
  return null;
};

export default HeroSelector;
