'use client';
import { toBoolean } from '@mnd-frontend/ts';
import { Icons } from '@mnd-frontend/ui';
import { usePathname } from 'next/navigation';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { track } from '../../lib/tracker';
import { mapLangToLocale } from '../../utils/locale';
import { CustomerCasePageFragment, PostPageFragment } from '../../wp-generated/types';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import { getButtonStyling } from '../Button/styling';
import { getModule } from '../CmsPageAppRouter';
import { ArchivePagesMetadata } from '../Metadata';
import GlobalModule from '../modules/GlobalModule';
import TextBlocksModule from '../modules/TextBlocksModule';
import { SrOnly } from '../SrOnly';

const Container = styled.section`
  img {
    width: 100%;
    height: 100%;
  }
  --aip-img-width: min(92vw, 800px + 10rem);
  --aip-img-wrap-margin: 20rem;
  --aip-neg-img-wrap-margin: calc(-1 * var(--aip-img-wrap-margin));
  --aip-default-padding: 1.25rem;
  --aip-max-width: 800px;
  --customer-case-header-max-width: 1125px;
  --post-header-max-width: var(--aip-max-width);
  position: relative;
`;

const Header = styled.header<{ $background: string; $color: string; $isPost: boolean }>`
  background-color: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  ${({ $isPost }) =>
    $isPost
      ? css`
          padding: calc(var(--spacing-large) * 2) calc((100vw - var(--post-header-max-width)) / 2);
        `
      : css`
          padding: calc(var(--spacing-large) * 2)
            calc((100vw - var(--customer-case-header-max-width)) / 2);
          display: grid;
          grid-template-columns: 1fr;
          gap: 1.25rem;
          align-items: center;
          @media (min-width: 1080px) {
            grid-template-columns: 1fr 1fr;
          }
        `}

  h1 {
    width: 90%;
    margin: 0 auto;
    font-size: clamp(1.5rem, 7vw, 2.75rem);
    font-weight: bold;
    letter-spacing: -0.1rem;
    line-height: 1.2;
  }
  ul {
    width: 90%;
    margin: 1rem auto;
  }
  li {
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.2rem 0.52rem;
    background-color: var(--color-deep-purple);
    border-radius: 2rem;
  }
`;

const Ul = styled.ul`
  list-style: none;
  display: flex;
  gap: 0.5rem;
  padding: 0;
`;

const ImgWrapper = styled.div<{ $isPost: boolean }>`
  width: var(--aip-img-width);
  aspect-ratio: 16 / 9;
  overflow: hidden;
  margin-top: 1.5rem;
  margin-bottom: calc(-1 * (1.5rem * 2));
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  ${({ $isPost }) =>
    $isPost
      ? css`
          border-radius: 20px;
        `
      : css`
          margin-bottom: calc(var(--aip-neg-img-wrap-margin) + (var(--aip-default-padding) * 4));
        `}

  @media (min-width: 1080px) {
    ${({ $isPost }) =>
      $isPost
        ? css`
            margin-bottom: var(--aip-neg-img-wrap-margin);
          `
        : css`
            left: unset;
            transform: unset;
            width: unset;
            margin-bottom: unset;
          `}
  }
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

const ContentWrapper = styled.div<{ $isPost: boolean }>`
  ${({ $isPost }) =>
    $isPost
      ? css`
          padding: 3rem 1.25rem;
        `
      : css`
          padding: calc(var(--aip-img-wrap-margin) - var(--aip-default-padding) * 6) 1.25rem;
        `}

  @media (min-width: 1080px) {
    ${({ $isPost }) =>
      $isPost
        ? css`
            padding: calc(var(--aip-img-wrap-margin) - var(--aip-default-padding))
              calc((100vw - var(--aip-max-width)) / 2);
          `
        : css`
            padding: 6rem calc((100vw - var(--aip-max-width)) / 2);
          `}
  }
`;

const Wysiwyg = styled.div`
  & h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
  }

  & h3 {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
  }

  & > p:first-child {
    font-size: 1.2rem;
    @media (min-width: 1080px) {
      font-size: 1.5rem;
    }
    font-weight: 600;
  }
  & p {
    margin-bottom: 1.25rem;
  }
  & a {
    color: var(--link-color);
    text-decoration: underline;
  }
  & img,
  svg,
  video {
    height: auto;
    max-width: 100%;
  }
  & blockquote {
    position: relative;
    margin: 0;
    display: flex;
    gap: 1.5rem;
    margin: var(--spacing-large) 0;
    & p {
      font-style: italic;
      color: var(--color-gray);
      line-height: 1.2;
      font-size: 1.25rem;
      @media (min-width: 1080px) {
        font-size: 1.5rem;
      }
    }
  }
  & blockquote::before {
    display: block;
    content: url('data:image/svg+xml;base64,CiAgPHN2ZwogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgZmlsbD0ibm9uZSIKICAgIHZpZXdCb3g9IjAgMTUwIDEwMDAgNzUwIgogICAgc3Ryb2tlV2lkdGg9IjEuNSIKICAgIHN0cm9rZT0iI2ZmNDQ3MyIKICA+CiAgICA8cGF0aAogICAgICBzdHJva2VMaW5lY2FwPSJyb3VuZCIKICAgICAgc3Ryb2tlTGluZWpvaW49InJvdW5kIgogICAgICBmaWxsPSIjZmY0NDczIgogICAgICBkPSJNOTY1LjIgMjEzVjU5Ni41SDk2NS4yVjYzMS4zIDczNS43TDgzNi41IDYwNyA4MjYuMSA1OTYuNUg4MTEuMyA1ODEuN1YyMTNIOTY1LjJNMTAwMCAxNzguM0g1NDdWNjMxLjNIODExLjNMMTAwMCA4MjBWNjMxLjNIMTAwMFYxNzguM0wxMDAwIDE3OC4zWk00MTguMyAyMTMuOVY1OTcuNEg0MTguM1Y2MzIuMiA3MzYuNUwyODkuNiA2MDcuOCAyNzkuMSA1OTcuNEgyNjQuMyAzNC44VjIxMy45SDQxOC4zTTQ1MyAxNzkuMUgwVjYzMi4ySDI2NC4zTDQ1MyA4MjAuOVY2MzIuMkg0NTNWMTc5LjFMNDUzIDE3OS4xWiIKICAgIC8+CiAgPC9zdmc+Cg==');
    color: var(--color-primary);
    width: clamp(2rem, 5vw, 6vw);
    height: clamp(2rem, 5vw, 6vw);
    flex-shrink: 0;
  }
  & .button {
    ${getButtonStyling({ $variant: 'primary' })}
    display: inline-flex;
  }
`;

const ArticleFooter = styled.footer`
  margin-top: 3.5rem;
  display: grid;
  grid-auto-rows: 1fr;
  align-items: center;

  time {
    font-style: italic;
  }
`;

const FooterSections = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  ul {
    a {
      text-decoration: underline;
    }
  }
`;

interface Category {
  id: string;
  name: string | null;
  link: string | null;
}

const CategoriesList = ({ categories }: { categories: Category[] }) => (
  <Ul>
    {categories.map(category => (
      <li key={category.id}>
        {category.link ? <a href={category.link}>{category.name}</a> : <span>{category.name}</span>}
      </li>
    ))}
  </Ul>
);

const PublishDate = ({ text, date, locale }: { text: string; date: string; locale?: string }) => {
  const dateFormatter = useMemo(() => {
    return new Intl.DateTimeFormat(mapLangToLocale(locale), {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  }, [locale]);

  return (
    <p>
      {text}:{' '}
      <time suppressHydrationWarning dateTime={date}>
        {dateFormatter.format(new Date(date))}
      </time>
    </p>
  );
};

const isPostPage = (
  page: PostPageFragment | CustomerCasePageFragment,
): page is PostPageFragment => {
  return page.__typename === 'Post';
};

interface ArchiveItemPageProps {
  page: PostPageFragment | CustomerCasePageFragment;
  locale?: string;
}

const archiveTypeTitleKey = {
  CustomerCase: 'marketing_breadcrumb_customer_case',
  Post: 'marketing_breadcrumb_blog',
} as const;

const ArchiveItemPage = ({ page, locale }: ArchiveItemPageProps) => {
  const { t } = useTranslation();
  const { seo } = page;
  const isPost = isPostPage(page);
  const background = isPost ? 'var(--color-purple)' : 'var(--color-orange)';
  const color = isPost ? 'var(--color-white)' : 'var(--color-deep-purble)';
  const pathname = usePathname();
  const breadcrumbTextKey = page.__typename ? archiveTypeTitleKey[page.__typename] : null;
  const categories = isPost ? page.categories?.nodes || [] : [];
  const date = isPost ? page?.date : '';

  const trackShare = (socialProvider: string) => {
    track({
      event: 'share_content_intention',
      traits: {
        event_info: {
          article_id: page.id,
          article_name: page.title ?? '',
          share_method: socialProvider,
          position: 'footer',
        },
      },
    });
  };

  return (
    <>
      <ArchivePagesMetadata locale={locale} seo={seo} searchResultPage={false} />
      <Container>
        <BreadcrumbsComponent
          breadcrumbs={[
            { text: 'Start', url: `/${locale}/` },
            breadcrumbTextKey && {
              text: t(breadcrumbTextKey),
              url: pathname.split('/').slice(0, 3).join('/') + '/', // '', locale, archive_type
            },
            page.title
              ? {
                  text: page.title,
                  url: pathname,
                }
              : null,
          ].filter(toBoolean)}
          background={background}
        />
        <article>
          <Header $background={background} $color={color} $isPost={isPost}>
            <h1>{page.title}</h1>
            {categories.length > 0 && <CategoriesList categories={categories} />}
            <ImgWrapper $isPost={isPost}>
              <Img src={page.featuredImage?.node?.mediaItemUrl || ''} alt="" />
            </ImgWrapper>
          </Header>
          <ContentWrapper $isPost={isPost}>
            <Wysiwyg dangerouslySetInnerHTML={{ __html: page.content || '' }} />
            {isPost && (
              <ArticleFooter>
                {date && (
                  <PublishDate text={t('material_state_published')} date={date} locale={locale} />
                )}
                {categories.length > 0 && (
                  <FooterSections aria-labelledby="categories-label">
                    <p id="categories-label">{t('blog-post-category-info')}:</p>
                    <CategoriesList categories={categories} />
                  </FooterSections>
                )}
                <FooterSections aria-labelledby="share-blog-label">
                  <p id="share-blog-label">{t('share-blog-post')}:</p>
                  <Ul>
                    <li>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(page?.link ?? '')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackShare('Facebook')}
                      >
                        <SrOnly>{t('reports_upsell-block-share-header')} - facebook</SrOnly>
                        <Icons.Facebook $size="xs" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://x.com/intent/tweet?url=${encodeURIComponent(page?.link ?? '')}&text=${encodeURIComponent(`${page?.title} | Mynewsdesk`)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackShare('X')}
                      >
                        <SrOnly>{t('reports_upsell-block-share-header')} - X (Twitter)</SrOnly>
                        <Icons.X $size="xs" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(page?.link ?? '')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackShare('LinkedIn')}
                      >
                        <SrOnly>{t('reports_upsell-block-share-header')} - LinkedIn</SrOnly>
                        <Icons.LinkedIn $size="xs" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:?subject=${encodeURIComponent(`${page.title} | Mynewsdesk`)}&body=${encodeURIComponent(page?.link ?? '')}`}
                        onClick={() => trackShare('Email')}
                      >
                        <SrOnly>{t('reports_upsell-block-share-header')} - Email</SrOnly>
                        <Icons.Email $size="xs" aria-hidden="true" />
                      </a>
                    </li>
                  </Ul>
                </FooterSections>
              </ArticleFooter>
            )}
          </ContentWrapper>
        </article>
      </Container>
      {!isPostPage(page) &&
        page.modules?.flexibleModules?.map((moduleData, index) => {
          if (moduleData?.__typename === 'ModulesFlexibleModulesGlobalModuleLayout') {
            return <GlobalModule key={index} moduleData={moduleData} locale={locale} />;
          } else if (moduleData?.__typename === 'ModulesFlexibleModulesTextBlockLayout') {
            return <TextBlocksModule key={index} moduleData={moduleData} />;
          } else {
            return <Fragment key={index}>{getModule(moduleData, locale)}</Fragment>;
          }
        })}
    </>
  );
};

export default ArchiveItemPage;
