import { TFunction } from 'i18next';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { Locales, mapSiteLocaleToLangCountryCode } from '../../utils/locale';
import { PostTypeEnum } from '../../wp-generated/types';
import { baseMetadata, defaultOgImage, Metadata } from './archivePageMetadata';

export interface Seo {
  breadcrumbs: Array<{
    __typename?: 'SEOPostTypeBreadcrumbs';
    text: string | null;
    url: string | null;
  } | null> | null;
  metaDesc: string | null;
  metaKeywords: string | null;
  metaRobotsNoindex: string | null;
  metaRobotsNofollow: string | null;
  opengraphTitle: string | null;
  opengraphType: string | null;
  opengraphDescription: string | null;
  opengraphUrl: string | null;
  opengraphImage: {
    mediaItemUrl: string | null;
  } | null;
  title: string | null;
  schema: {
    raw: string | null;
  } | null;
}

interface BaseTemplateStructuredData {
  url: string;
  name: string;
  description?: string;
  language: string;
  postType: PostTypeEnum | null;
  searchResultPage: boolean;
}

const getDescription = ({
  t,
  postType,
  search,
}: {
  t: TFunction;
  postType: PostTypeEnum | null;
  search?: string;
}) => {
  switch (postType) {
    case PostTypeEnum.CustomerCase:
      if (search) {
        return t('public-sd-customer-case-search-desc', { search });
      } else {
        return t('public-sd-customer-case-desc');
      }
    case PostTypeEnum.Resource:
      if (search) {
        return t('public-sd-resource-search-desc', { search });
      } else {
        return t('public-sd-resource-desc');
      }
    case PostTypeEnum.Event:
      return t('public-sd-event-desc');
    case PostTypeEnum.Post:
      if (search) {
        return t('public-sd-post-search-desc', { search });
      } else {
        return t('public-sd-post-desc');
      }
    default:
      return '';
  }
};

const getStructuredData = ({
  url,
  name,
  description,
  language,
  postType,
  searchResultPage,
}: BaseTemplateStructuredData) => {
  const inLanguage = mapSiteLocaleToLangCountryCode(language).replace('_', '-');
  const pageType = ['WebPage'];
  searchResultPage ? pageType.push('SearchResultsPage') : pageType.push('CollectionPage');

  const structuredData = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': pageType,
        '@id': url,
        url: url,
        name,
        inLanguage,
        description,
        isPartOf: {
          '@id': `${url}#website`,
        },
      },
      {
        '@type': 'WebSite',
        '@id': `${url}#website`,
        url: url,
        name: 'Mynewsdesk',
        inLanguage,
        publisher: {
          '@id': 'https://www.mynewsdesk.com/#organization',
        },
        ...(postType !== PostTypeEnum.Event && {
          potentialAction: [
            {
              '@type': 'SearchAction',
              target: {
                '@type': 'EntryPoint',
                urlTemplate: `${url}?s={search_term_string}`,
              },
              'query-input': 'required name=search_term_string',
            },
          ],
        }),
      },
      {
        '@type': 'Organization',
        '@id': 'https://www.mynewsdesk.com/#organization',
        name: 'Mynewsdesk',
        url: 'https://www.mynewsdesk.com/',
        logo: {
          '@type': 'ImageObject',
          inLanguage,
          '@id': 'https://www.mynewsdesk.com/#logo',
          url: 'https://www.mynewsdesk.com/wp-content/uploads/2022/01/site-logo.svg',
          contentUrl: 'https://www.mynewsdesk.com/wp-content/uploads/2022/01/site-logo.svg',
          width: 238,
          height: 41,
          caption: 'Mynewsdesk',
        },
        image: {
          '@id': 'https://www.mynewsdesk.com/#logo',
        },
        sameAs: [
          'https://www.facebook.com/MynewsdeskSE',
          'https://x.com/mynewsdesk',
          'https://www.instagram.com/mynewsdesk/',
          'https://www.linkedin.com/company/mynewsdesk',
        ],
      },
    ],
  };
  return structuredData;
};

const localeMapper = (locale: string): Locales => {
  const mapping: { [key: string]: Locales } = {
    'se-sv': 'se-sv',
    'de-de': 'de-de',
    'dk-da': 'dk-da',
    'no-no': 'no-no',
    en: 'en',
  };
  return mapping[locale] || 'se-sv';
};

const postTypeMapper = (postType: string): PostTypeEnum | null => {
  const mapping: { [key: string]: PostTypeEnum } = {
    post: PostTypeEnum.Post,
    event: PostTypeEnum.Event,
    customer_case: PostTypeEnum.CustomerCase,
    resource: PostTypeEnum.Resource,
  };
  return mapping[postType.toLowerCase()] || null;
};

const getMetadata = ({
  t,
  postType,
  locale,
  seo,
}: {
  t: TFunction;
  postType?: string | null;
  locale?: string;
  seo?: Seo | null;
}): Metadata => {
  const ogLocale = mapSiteLocaleToLangCountryCode(locale);

  if (seo) {
    return {
      metaDesc: seo?.metaDesc || '',
      metaKeywords: seo?.metaKeywords || '',
      metaRobotsNoindex: seo?.metaRobotsNoindex || '',
      metaRobotsNofollow: seo?.metaRobotsNofollow || '',
      ogTitle: seo?.opengraphTitle || '',
      ogType: seo?.opengraphType || 'website',
      ogDescription: seo?.opengraphDescription || '',
      ogUrl: seo?.opengraphUrl || '',
      ogImage: seo?.opengraphImage?.mediaItemUrl || defaultOgImage,
      ogLocale,
      title: seo?.title || '',
    };
  } else {
    switch (postType) {
      case PostTypeEnum.Post:
        return {
          ...baseMetadata,
          metaDesc: t('meta-description-post-public'),
          metaKeywords: t('meta-keywords-post-public'),
          ogTitle: t('meta-og-title-post-public'),
          ogDescription: t('meta-og-description-post-public'),
          ogUrl: t('meta-og-url-post-public'),
          ogLocale,
          title: t('meta-title-post-public'),
        };
      case PostTypeEnum.Event:
        return {
          ...baseMetadata,
          metaDesc: t('meta-description-event-public'),
          metaKeywords: t('meta-keywords-event-public'),
          ogTitle: t('meta-og-title-event-public'),
          ogDescription: t('meta-og-description-event-public'),
          ogUrl: t('meta-og-url-event-public'),
          ogLocale,
          title: t('meta-title-event-public'),
        };
      case PostTypeEnum.CustomerCase:
        return {
          ...baseMetadata,
          metaDesc: t('meta-description-customer-case-public'),
          metaKeywords: t('meta-keywords-customer-case-public'),
          ogTitle: t('meta-og-title-customer-case-public'),
          ogDescription: t('meta-og-description-customer-case-public'),
          ogUrl: t('meta-og-url-customer-case-public'),
          ogLocale,
          title: t('meta-title-customer-case-public'),
        };
      case PostTypeEnum.Resource:
        return {
          ...baseMetadata,
          metaDesc: t('meta-description-resource-public'),
          metaKeywords: t('meta-keywords-resource-public'),
          ogTitle: t('meta-og-title-resource-public'),
          ogDescription: t('meta-og-description-resource-public'),
          ogUrl: t('meta-og-url-resource-public'),
          ogLocale,
          title: t('meta-title-resource-public'),
        };
      default:
        return {
          ...baseMetadata,
          ogLocale,
        };
    }
  }
};

export const ArchivePagesMetadata = ({
  locale,
  postType,
  seo,
  indexPage = true,
  canonicalPath,
  searchResultPage,
  search,
}: {
  locale?: string;
  postType?: string | null;
  seo?: Seo | null;
  indexPage?: boolean;
  canonicalPath?: string;
  searchResultPage: boolean;
  search?: string;
}) => {
  const { t } = useTranslation();
  const mappedPostType = postTypeMapper(postType || '');
  const mappedLocale = localeMapper(locale || 'en');
  const metadata = getMetadata({ t, postType: mappedPostType, locale: mappedLocale, seo });
  const canonicalUrl =
    (process.env.NEXT_PUBLIC_PRIME_URL || 'https://www.mynewsdesk.com') + canonicalPath;

  return (
    <Head>
      <title>{metadata.title || 'Mynewsdesk'}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <meta
        name="robots"
        content={`${indexPage ? 'index' : 'noindex'}, follow, max-image-preview: large, max-snippet: -1, max-video-preview: -1`}
      />
      <meta property="og:site_name" content="Mynewsdesk" />
      {metadata.metaDesc && <meta name="description" content={metadata.metaDesc} />}
      {metadata.metaKeywords && <meta name="keywords" content={metadata.metaKeywords} />}
      {metadata.ogTitle && <meta property="og:title" content={metadata.ogTitle} />}
      {metadata.ogDescription && (
        <meta property="og:description" content={metadata.ogDescription} />
      )}
      {metadata.ogUrl && <meta property="og:url" content={metadata.ogUrl} />}
      {metadata.ogLocale && <meta property="og:locale" content={metadata.ogLocale} />}
      {metadata.ogType && <meta property="og:type" content={metadata.ogType} />}
      {metadata?.ogImage && <meta property="og:image" content={metadata.ogImage} />}
      <link rel="preconnect" href="https://cdn.cookielaw.org" />
      {canonicalPath && <link rel="canonical" href={`${canonicalUrl}`} />}
      <script type="application/ld+json">
        {seo?.schema?.raw
          ? seo.schema.raw
          : JSON.stringify(
              getStructuredData({
                url: canonicalUrl,
                name: metadata.title || `${mappedPostType} - Mynewsdesk`,
                description: getDescription({ t, postType: mappedPostType, search }),
                language: locale || 'en',
                postType: mappedPostType,
                searchResultPage,
              }),
            )}
      </script>
    </Head>
  );
};
