'use client';
import React, { useState } from 'react';
import { useSetLanguageLinks } from '../contexts/LanguageLinksContext';
import {
  CustomerCasePageFragment,
  GlobalModulesFragment,
  PageFragment,
  PostPageFragment,
} from '../wp-generated/types';
import ArchiveItemPage from './ArchiveItemPage';
import { BreadcrumbsComponent } from './Breadcrumbs';
import ComparisonTable from './modules/ComparisonTableModule/ComparisonTableModule';
import CustomerCasesModule from './modules/CustomerCasesModule';
import EmployeesModule from './modules/EmployeesModule';
import Faq from './modules/FaqModule/FaqModule';
import { FeaturedPages, FeaturedPosts } from './modules/FeaturedModule';
import GlobalModule from './modules/GlobalModule';
import HeroSelector from './modules/HeroModule';
import IframeModule from './modules/IframeModule';
import LatestPostsModule from './modules/LatestPostsModule';
import LatestResources from './modules/LatestResources';
import LogosModule from './modules/LogosModule';
import { MissingModule } from './modules/MissingModule';
import Notice from './modules/Notice';
import NumbersModule from './modules/NumbersModule';
import PageAnchor from './modules/PageAnchor';
import PricingPlans from './modules/PricingPlansModule/PricingPlansModule';
import RelatedPosts from './modules/RelatedPosts';
import Reviews from './modules/ReviewsModule';
import TestimonialsModule from './modules/TestimonialsModule';
import TextBlocksModule from './modules/TextBlocksModule';
import TrialSignupModule from './modules/TrialSignupModule';
import Video from './modules/VideoModule';
import WaveModule from './modules/WaveModule';

interface CmsPageAppRouterProps {
  locale: string;
  page: PageFragment | CustomerCasePageFragment | PostPageFragment | null;
}

export function getModule(
  moduleData:
    | NonNullable<NonNullable<PageFragment['modules']>['flexibleModules']>[0]
    | NonNullable<GlobalModulesFragment['data']>[0],
  locale?: string,
) {
  if (moduleData?.__typename === 'ModulesFlexibleModulesTextBlocksLayout') {
    return <TextBlocksModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesLogosLayout') {
    return <LogosModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesTestimonialsLayout') {
    return <TestimonialsModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesNumbersLayout') {
    return <NumbersModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesLatestPostsLayout') {
    return <LatestPostsModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesEmployeesLayout') {
    return <EmployeesModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesFeaturedPagesLayout') {
    return <FeaturedPages moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesFeaturedPostsLayout') {
    return <FeaturedPosts moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesReviewsLayout') {
    return <Reviews moduleData={moduleData} locale={locale} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesFaqLayout') {
    return <Faq moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPricingPlansLayout') {
    return <PricingPlans moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesComparisonTableLayout') {
    return <ComparisonTable moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesVideoLayout') {
    return <Video moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesFeaturedCustomerCasesLayout') {
    return <CustomerCasesModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesWaveLayout') {
    return <WaveModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesLatestResourcesLayout') {
    return <LatestResources moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesTrialSignupFormLayout') {
    return <TrialSignupModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesRelatedPostsLayout') {
    return <RelatedPosts moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesIframeLayout') {
    return <IframeModule moduleData={moduleData} />;
  } else if (moduleData?.__typename === 'ModulesFlexibleModulesPageAnchorLayout') {
    return <PageAnchor moduleData={moduleData} />;
  } else {
    return <MissingModule typename={moduleData?.__typename ?? 'unknown'} />;
  }
}

export default function CmsPageAppRouter({ locale, page }: CmsPageAppRouterProps) {
  const render = (page as any).preview ? (page as any).preview.node : page;

  const [noticeIsOpen, setNoticeIsOpen] = useState(true);
  const isFrontPage = render && 'isFrontPage' in render && render.isFrontPage;
  useSetLanguageLinks(
    isFrontPage ? [] : render && 'translations' in render ? render.translations : [],
  );
  if (render === null) {
    return null;
  }
  return (
    <main>
      {render.__typename === 'Page' ? (
        <>
          {(render.seo?.breadcrumbs?.length || 0) > 1 && !isFrontPage && (
            <BreadcrumbsComponent
              breadcrumbs={
                (render?.seo?.breadcrumbs?.filter((b: any) => b?.url && b?.text) as {
                  url: string;
                  text: string;
                }[]) ?? []
              }
              hero={render.hero}
            />
          )}
          {render.stickyModules?.notice?.title && noticeIsOpen && (
            <Notice
              notice={render.stickyModules?.notice}
              noticeIsOpen={noticeIsOpen}
              setNoticeIsOpen={setNoticeIsOpen}
            />
          )}
          <HeroSelector hero={render.hero} pageName={render.title ?? ''} />
          {render.modules?.flexibleModules?.map((moduleData: any, index: number) => {
            if (moduleData?.__typename === 'ModulesFlexibleModulesGlobalModuleLayout') {
              return <GlobalModule key={index} moduleData={moduleData} locale={locale} />;
            } else if (moduleData?.__typename === 'ModulesFlexibleModulesTextBlockLayout') {
              return <TextBlocksModule key={index} moduleData={moduleData} />;
            } else {
              return <React.Fragment key={index}>{getModule(moduleData, locale)}</React.Fragment>;
            }
          })}
        </>
      ) : render.__typename === 'CustomerCase' || render.__typename === 'Post' ? (
        <ArchiveItemPage locale={locale} page={render} />
      ) : null}
    </main>
  );
}
