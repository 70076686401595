import { useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';

export const liBeforeStyles = css<{ $primaryColor?: string }>`
  li::before {
    position: absolute;
    line-height: 15px;
    left: 0;
    content: ${props =>
      `url('data:image/svg+xml, <svg width="17" height="22" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"><path d="M976 154C1006 184 1006 234 976 266L398 846C368 876 318 876 286 846L24 582C-6 552-6 502 24 470 54 440 104 440 136 470L344 678 866 154C896 124 946 124 976 154Z" fill="${props.$primaryColor ? encodeURIComponent(props.$primaryColor) : 'current-color'}" /></svg>')`};
  }
`;

export const usePrimaryColor = () => {
  const [color, setColor] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!containerRef.current) return;
    setColor(getComputedStyle(containerRef.current).getPropertyValue('--color-primary'));
  }, []);

  return { ref: containerRef, color: color };
};
