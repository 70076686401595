type OgDataType = {
  [key: string]: {
    ogTitle: string;
    ogUrl: string;
    ogDescription: string;
  };
};

export const monitorTrialOgData: OgDataType = {
  en: {
    ogTitle: 'Free trial',
    ogUrl: 'https://www.mynewsdesk.com/en/monitor-reports-trial/',
    ogDescription:
      "Try all of Mynewsdesk's functions, except publishing, completely free of charge for 10 days. No commitment period, no hidden fees.",
  },
  'se-sv': {
    ogTitle: 'Prova gratis',
    ogUrl: 'https://www.mynewsdesk.com/se-sv/monitor-reports-trial/',
    ogDescription:
      'Prova på Mynewsdesks alla funktioner förutom att publicera helt kostnadsfritt i 10 dagar. Ingen bindningstid, inga dolda avgifter.',
  },
  'de-de': {
    ogTitle: 'Teste kostenfrei',
    ogUrl: 'https://www.mynewsdesk.com/de-de/monitor-reports-trial/',
    ogDescription:
      'Mynewsdesk ist die smarte, KI-gestützte Komplettlösung für PR und Kommunikation auf dem Markt. Starten Sie jetzt Ihre kostenfreie Testphase.',
  },
  'dk-da': {
    ogTitle: 'Prøv gratis',
    ogUrl: 'https://www.mynewsdesk.com/dk-da/monitor-reports-trial/',
    ogDescription:
      'Prøv Mynewsdesk helt gratis i 10-dages, den førende alt-i-en-løsning for alle dine PR- og kommunikationsbehov.',
  },
  'no-no': {
    ogTitle: 'Prøv gratis',
    ogUrl: 'https://www.mynewsdesk.com/no-no/monitor-reports-trial/',
    ogDescription:
      'Prøv alle funksjonene til Mynewsdesk, bortsett fra å publisere, helt gratis i 10 dager. Ingen bindingstid, ingen skjulte gebyrer.',
  },
};

export const productTourContactOgData: OgDataType = {
  en: {
    ogTitle: 'Contact sales',
    ogUrl: 'https://www.mynewsdesk.com/en/product-tour/contact-sales/',
    ogDescription: 'Contact our sales team to learn more about Mynewsdesk and how we can help you.',
  },
  'se-sv': {
    ogTitle: 'Kontakta oss',
    ogUrl: 'https://www.mynewsdesk.com/se-sv/product-tour/contact-sales/',
    ogDescription:
      'Boka en kostnadsfria demo för en genomgång av vårt PR-verktyg och råd om vilket abonnemang som passar din verksamhet bäst.',
  },
  'no-no': {
    ogTitle: 'Kontakt oss',
    ogUrl: 'https://www.mynewsdesk.com/no-no/product-tour/contact-sales/',
    ogDescription:
      'Boka en kostnadsfria demo för en genomgång av vårt PR-verktyg och råd om vilket abonnemang som passar din verksamhet bäst.',
  },
  'de-de': {
    ogTitle: 'Kontaktieren Sie uns',
    ogUrl: 'https://www.mynewsdesk.com/de-de/product-tour/contact-sales/',
    ogDescription:
      'In der individuellen Demo zeigen wir Ihnen, wie Sie Ihre persönlichen PR-Ziele erreichen und erfolgreicher werden.',
  },
  'dk-da': {
    ogTitle: 'Kontakt os',
    ogUrl: 'https://www.mynewsdesk.com/dk-da/product-tour/contact-sales/',
    ogDescription:
      'Få indblik i det effektive PR-værktøj med vores eksperter. Indtast dine kontaktinformationer for at booke en demo.',
  },
};

export const productTourSignupOgData: OgDataType = {
  en: {
    ogTitle: 'Free trial',
    ogUrl: 'https://www.mynewsdesk.com/en/product-tour/signup/',
    ogDescription:
      "Try all of Mynewsdesk's functions, except publishing, completely free of charge for 10 days. No commitment period, no hidden fees.",
  },
  'se-sv': {
    ogTitle: 'Prova gratis',
    ogUrl: 'https://www.mynewsdesk.com/se-sv/product-tour/signup/',
    ogDescription:
      'Prova på Mynewsdesks alla funktioner förutom att publicera helt kostnadsfritt i 10 dagar. Ingen bindningstid, inga dolda avgifter.',
  },
  'de-de': {
    ogTitle: 'Teste kostenfrei',
    ogUrl: 'https://www.mynewsdesk.com/de-de/product-tour/signup/',
    ogDescription:
      'Mynewsdesk ist die smarte, KI-gestützte Komplettlösung für PR und Kommunikation auf dem Markt. Starten Sie jetzt Ihre kostenfreie Testphase.',
  },
  'dk-da': {
    ogTitle: 'Prøv gratis',
    ogUrl: 'https://www.mynewsdesk.com/dk-da/product-tour/signup/',
    ogDescription:
      'Prøv Mynewsdesk helt gratis i 10-dages, den førende alt-i-en-løsning for alle dine PR- og kommunikationsbehov.',
  },
  'no-no': {
    ogTitle: 'Prøv gratis',
    ogUrl: 'https://www.mynewsdesk.com/no-no/product-tour/signup/',
    ogDescription:
      'Prøv alle funksjonene til Mynewsdesk, bortsett fra å publisere, helt gratis i 10 dager. Ingen bindingstid, ingen skjulte gebyrer.',
  },
};
