export interface Metadata {
  metaDesc?: string;
  metaKeywords?: string;
  metaRobotsNoindex: string;
  metaRobotsNofollow: string;
  ogTitle?: string;
  ogType?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImage?: string;
  ogLocale?: string;
  title?: string;
}

export const defaultOgImage =
  'https://www.mynewsdesk.com/wp-content/themes/mynewsdesk/dist/assets/site-logo.svg';

export const baseMetadata: Metadata = {
  metaDesc: '',
  metaKeywords: '',
  metaRobotsNoindex: 'index',
  metaRobotsNofollow: 'follow',
  ogTitle: '',
  ogType: 'website',
  ogDescription: '',
  ogUrl: '',
  ogImage: defaultOgImage,
  ogLocale: '',
  title: '',
};
