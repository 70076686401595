import { ButtonVariants } from '../Button/styling';

export function moduleBackgroundToCSS(bgColor?: string | (string | null)[] | null): string {
  let backgroundColor = 'var(--color-white)';
  const color = bgColor && Array.isArray(bgColor) ? bgColor[0] : bgColor;
  if (color?.includes('gradient-2')) {
    backgroundColor =
      'linear-gradient(289.69deg, rgb(78, 73, 199) 8.94%, rgb(255, 68, 115) 80.65%);';
  } else if (color?.includes('gradient-3')) {
    backgroundColor = 'linear-gradient(rgb(24, 22, 70) 0%, rgb(78, 73, 199) 100%);';
  } else if (color?.includes('red')) {
    backgroundColor = 'var(--color-primary)';
  } else if (color?.includes('pink')) {
    backgroundColor = 'var(--color-pink)';
  } else if (color?.includes('orange')) {
    backgroundColor = 'var(--color-orange)';
  } else if (color?.includes('deep-purple')) {
    backgroundColor = 'var(--color-deep-purple)';
  } else if (color?.includes('purple')) {
    backgroundColor = 'var(--color-purple)';
  } else if (color?.includes('gray')) {
    backgroundColor = 'var(--color-lightgray)';
  } else if (color?.includes('transparent')) {
    backgroundColor = 'transparent';
  }
  return backgroundColor;
}

export function chooseLightOrDarkTextColor(bgColor?: string | (string | null)[] | null): string {
  const color = bgColor && Array.isArray(bgColor) ? bgColor[0] : bgColor;
  if (
    color?.includes('gradient-3') ||
    color?.includes('gradient-2') ||
    color?.includes('purple') ||
    color?.includes('red') ||
    color?.includes('deep-purple')
  ) {
    return 'var(--color-white)';
  } else {
    return 'var(--text-color)';
  }
}

export function chooseButtonColorBasedOnBackground(
  bgColor?: (string | null)[] | null,
): ButtonVariants {
  if (
    bgColor?.includes('gradient-2') ||
    bgColor?.includes('red') ||
    bgColor?.includes('pink') ||
    bgColor?.includes('deep-purple')
  ) {
    return 'purple';
  } else if (bgColor?.includes('gray')) {
    return 'text';
  } else {
    return 'primary';
  }
}

export function getColorForCheckmark(bgColor?: (string | null)[] | null): string {
  if (bgColor?.includes('red') || bgColor?.includes('gradient-2')) {
    return '#181646';
  } else {
    return '#ff4473';
  }
}
