import { PageAnchorFragment } from '../../../wp-generated/types';

const anchorPrefix = 'page-anchor-';

const PageAnchor = ({ moduleData }: { moduleData: PageAnchorFragment }) => {
  const { anchor } = moduleData;

  if (!anchor) return null;
  const anchorId = anchorPrefix + anchor.replace(/^#/, '');

  return <div id={anchorId} ariah-hidden="true" />;
};

export default PageAnchor;
