import styled from 'styled-components';
import { WaveFragment } from '../../../wp-generated/types';
import { moduleBackgroundToCSS } from '../getModuleColors';

const Wave = styled.div<{ $background: string }>`
  background: ${({ $background }) =>
    `radial-gradient(farthest-side, transparent 99.95%, ${$background} 100%)`};
  background-position: bottom;
  background-size: 365vw 365vw;
  height: 7.5vw;
  margin-top: -7.5vw;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  z-index: 1;
`;

const WaveModule = ({ moduleData }: { moduleData: WaveFragment }) => {
  const background = moduleBackgroundToCSS(moduleData?.bgColor);
  return <Wave $background={background} aria-hidden="true" />;
};

export default WaveModule;
