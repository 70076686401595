'use client';
import styled, { css } from 'styled-components';
import { PageFragment } from '../wp-generated/types';
import { chooseLightOrDarkTextColor } from './modules/getModuleColors';

const BreadCrumbs = styled.div<{ $backgroundIsWhite: boolean }>`
  position: absolute;
  text-overflow: ellipsis;
  z-index: 90;
  background-color: transparent;
  font-size: var(--font-size-small);
  width: fit-content;
  margin-left: min(var(--spacing-medium), var(--site-padding));
  overflow: hidden;
  display: flex;

  ${({ $backgroundIsWhite }) =>
    !$backgroundIsWhite &&
    css`
      margin-top: min(var(--spacing-small), var(--site-padding));
    `}
`;

const BreadCrumb = styled.div<{ $textColor: string; $blueLinks: boolean }>`
  display: flex;
  flex-direction: row;
  color: ${({ $textColor }) => $textColor};

  &:not(:last-child) {
    ${({ $blueLinks }) =>
      $blueLinks &&
      css`
        color: var(--link-color);
      `};
    :after {
      color: ${({ $textColor }) => $textColor};
      content: '/';
      margin: 0 var(--spacing-x-small);
    }
  }
`;

function getHeroBackgroundColor(hero: PageFragment['hero']): (string | null)[] | null {
  switch (hero?.heroTemplate?.[0]) {
    case 'hero':
      return hero?.hero?.bgColor || null;
    case 'hero-center':
      return hero?.heroCenter?.bgColor || null;
    case 'hero-form':
      if (hero?.heroForm?.image?.node?.link) {
        return ['image'];
      } else {
        return hero?.heroForm?.bgColor || null;
      }
    case 'hero-trial':
      return hero?.heroTrial?.bgColor || null;
    case 'hero-bg':
      return ['image'];
    case 'hero-ai':
      return ['image'];
    default:
      return null;
  }
}

export const BreadcrumbsComponent = ({
  breadcrumbs,
  hero,
  background = null,
}: {
  breadcrumbs: {
    url: string;
    text: string;
  }[];
} & (
  | {
      hero?: PageFragment['hero'];
      background?: undefined;
    }
  | { background: string | null; hero?: undefined }
)) => {
  let breadCrumbsTextColor = '';
  let heroBackgroundColor: (string | null)[] = [];
  heroBackgroundColor = (hero && getHeroBackgroundColor(hero)) || [background] || ['transparent'];
  breadCrumbsTextColor = chooseLightOrDarkTextColor(heroBackgroundColor) || 'var(--text-color)';

  return (
    <BreadCrumbs
      $backgroundIsWhite={
        heroBackgroundColor.includes('transparent') || heroBackgroundColor.includes('white')
      }
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <BreadCrumb
          key={index}
          $textColor={breadCrumbsTextColor}
          $blueLinks={breadCrumbsTextColor === 'var(--text-color)'}
        >
          <a
            href={breadcrumb?.url || ''}
            dangerouslySetInnerHTML={{
              __html: breadcrumb?.text || '',
            }}
          />
        </BreadCrumb>
      ))}
    </BreadCrumbs>
  );
};
