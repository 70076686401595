import { useMediaQuery } from '@mnd-frontend/hooks';
import styled, { css } from 'styled-components';
import { TestimonialsFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import { moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.section<{ $background: string; $symmetricStyling: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: ${({ $background }) => $background};
  padding: ${({ $symmetricStyling }) =>
    $symmetricStyling && 'var(--spacing-2x-large) var(--site-padding)'};
  ${({ $symmetricStyling }) =>
    $symmetricStyling &&
    css`
      align-items: center;
    `}
`;

const Title = styled.div`
  font-weight: var(--font-weight-extra-bold);
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-large);
`;

const TestimonialsContainer = styled.div<{ $symmetricStyling: boolean; $numberOfItems: number }>`
  ${({ $symmetricStyling, $numberOfItems }) =>
    $symmetricStyling &&
    css`
      display: grid;
      grid-gap: 3rem 1.6rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      ${$numberOfItems > 2
        ? css`
            @media (min-width: 1080px) {
              grid-template-columns: repeat(3, minmax(0, 1fr));
            }
          `
        : css`
            > * {
              max-width: calc((100vw - var(--site-padding)) / 3);
            }
          `}
    `}
`;

const TestimonialContainer = styled.article<{ $symmetricStyling: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: clip;
  overflow-clip-margin: content-box;
  ${({ $symmetricStyling }) =>
    $symmetricStyling &&
    css`
      text-align: center;
      flex-direction: column;
      overflow: visible;
      align-items: center;
    `}
`;

const ImageContainer = styled.div`
  height: 50vw;
  left: calc(var(--site-padding) * -1);
  max-width: none;
  min-height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(50vw - var(--spacing-x-large) + var(--site-padding));

  @media (min-width: 1200px) {
    left: 0;
    width: calc(50vw - 5rem);
  }
`;

const SymmetricTestimonialImageContainer = styled.div`
  text-align: center;
  position: relative;
`;

const TestimonialImage = styled.img`
  border-radius: 0 50% 50% 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center center;
`;

const SymmetricTestimonialTestimonialImage = styled.img`
  object-fit: cover;
  border-radius: 50%;
  width: 288px;
  height: 288px;
`;

const QuotationMarkIcon = styled.div<{ $symmetricStyling: boolean; $color: string }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${({ $color }) => $color};

  > svg {
    width: 72px;
  }

  @media (min-width: 1080px) {
    width: fit-content;
    ${({ $symmetricStyling }) =>
      $symmetricStyling &&
      css`
        position: absolute;
        top: -25px;
        right: 0;
      `}

    > svg {
      width: 112px;
    }
  }
`;

const QuotationMarkIconAbsolute = styled.div<{
  $symmetricStyling: boolean;
  $color: string;
}>`
  width: fit-content;
  position: absolute;
  top: 0;
  right: -10%;
  z-index: 1;
  color: ${({ $color }) => $color};

  > svg {
    width: 112px;
  }
`;

const Testimonial = styled.div<{ $symmetricStyling: boolean }>`
  font-size: var(--font-size-x-large);
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  font-style: italic;

  ${({ $symmetricStyling }) =>
    $symmetricStyling &&
    css`
      text-align: center;
    `}
`;

const TextAndQuotation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;

  @media (min-width: 768px) and (max-width: 1080px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

const TextContainer = styled.div<{ $symmetricStyling: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  padding: ${({ $symmetricStyling }) =>
    $symmetricStyling
      ? '18px 0 0'
      : 'var(--section-padding) var(--site-padding) var(--section-padding) 50vw'};

  ${({ $symmetricStyling }) =>
    !$symmetricStyling &&
    css`
      min-height: 100%;
      justify-content: center;
    `}
`;

const Button = styled(LinkButton)<{ $symmetricStyling: boolean; $multipleTestimonials: boolean }>`
  line-height: 1.2;
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ $symmetricStyling, $multipleTestimonials }) =>
    $multipleTestimonials
      ? css`
          margin-top: var(--spacing-large);
        `
      : $symmetricStyling
        ? css`
            margin-top: var(--spacing-medium);
          `
        : css`
            margin-top: 0;
          `}

  @media (min-width: 768px) {
    width: fit-content;
  }
`;

const RightQuotationSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 150 1000 750"
    strokeWidth="1.5"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="currentColor"
      d="M965.2 213V596.5H965.2V631.3 735.7L836.5 607 826.1 596.5H811.3 581.7V213H965.2M1000 178.3H547V631.3H811.3L1000 820V631.3H1000V178.3L1000 178.3ZM418.3 213.9V597.4H418.3V632.2 736.5L289.6 607.8 279.1 597.4H264.3 34.8V213.9H418.3M453 179.1H0V632.2H264.3L453 820.9V632.2H453V179.1L453 179.1Z"
    />
  </svg>
);

const ButtonComponent = ({
  moduleData,
  symmetricStyling,
  multipleTestimonials,
}: {
  moduleData: TestimonialsFragment;
  symmetricStyling: boolean;
  multipleTestimonials: boolean;
}) => {
  return moduleData.links?.map(
    (link, index) =>
      link?.link?.url && (
        <Button
          $symmetricStyling={symmetricStyling}
          $multipleTestimonials={multipleTestimonials}
          variant="purple"
          key={index}
          href={link?.link?.url}
          target={link?.link?.target || ''}
        >
          {link?.link?.title}
        </Button>
      ),
  );
};

const TestimonialsModule = ({ moduleData }: { moduleData: TestimonialsFragment }) => {
  const isMobile = useMediaQuery('@media (max-width: 767px)');
  const multipleTestimonials = (moduleData.data?.length || 0) > 1;
  const symmetricStyling = multipleTestimonials || isMobile;

  const quotationColor = moduleData.bgColor?.includes('gray')
    ? 'var(--color-primary)'
    : moduleData.bgColor?.includes('transparent') || moduleData.bgColor?.includes('white')
      ? 'var(--text-color)'
      : 'var(--color-white)';

  return (
    <>
      <Container
        $background={moduleBackgroundToCSS(moduleData.bgColor)}
        $symmetricStyling={symmetricStyling}
      >
        {moduleData.title && <Title>{moduleData.title}</Title>}
        <TestimonialsContainer
          $symmetricStyling={symmetricStyling}
          $numberOfItems={moduleData.data?.length ?? 0}
        >
          {moduleData.data?.map(
            (testimonial, index) =>
              ((index === 0 && isMobile) || !isMobile) && ( // On mobile, show only one testimonial
                <TestimonialContainer key={index} $symmetricStyling={symmetricStyling}>
                  {testimonial?.image && symmetricStyling ? (
                    <SymmetricTestimonialImageContainer>
                      <QuotationMarkIconAbsolute
                        $symmetricStyling={symmetricStyling}
                        $color={quotationColor}
                        aria-hidden="true"
                      >
                        <RightQuotationSvg />
                      </QuotationMarkIconAbsolute>
                      <SymmetricTestimonialTestimonialImage src={testimonial.image} alt="" />
                    </SymmetricTestimonialImageContainer>
                  ) : (
                    testimonial?.image && (
                      <ImageContainer>
                        <TestimonialImage src={testimonial.image} alt="" />
                      </ImageContainer>
                    )
                  )}
                  <TextContainer $symmetricStyling={symmetricStyling}>
                    <TextAndQuotation>
                      <Testimonial
                        $symmetricStyling={symmetricStyling}
                        dangerouslySetInnerHTML={{
                          __html: testimonial?.testimonial || '',
                        }}
                      />
                      {!symmetricStyling && (
                        <QuotationMarkIcon
                          $symmetricStyling={symmetricStyling}
                          $color={quotationColor}
                          aria-hidden="true"
                        >
                          <RightQuotationSvg />
                        </QuotationMarkIcon>
                      )}
                    </TextAndQuotation>
                    <span>{testimonial?.name || ''}</span>
                    {!multipleTestimonials && (
                      <ButtonComponent
                        moduleData={moduleData}
                        symmetricStyling={symmetricStyling}
                        multipleTestimonials={multipleTestimonials}
                      />
                    )}
                  </TextContainer>
                </TestimonialContainer>
              ),
          )}
        </TestimonialsContainer>
        {multipleTestimonials && (
          <ButtonComponent
            moduleData={moduleData}
            symmetricStyling={symmetricStyling}
            multipleTestimonials={multipleTestimonials}
          />
        )}
      </Container>
    </>
  );
};

export default TestimonialsModule;
