import styled from 'styled-components';
import { IframeFragment } from '../../../wp-generated/types';
import { chooseLightOrDarkTextColor, moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.section<{ $background: string; $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  gap: 1rem;
  padding: var(--section-padding) var(--site-padding);
`;

const Title = styled.h2`
  font-size: var(--font-size-large);
  margin: 0 auto;
  font-weight: bold;
  text-transform: uppercase;
`;

const IframeWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  max-height: 600px;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const IframeModule = ({ moduleData }: { moduleData: IframeFragment }) => {
  if (!moduleData || !moduleData?.url) return null;
  return (
    <Container
      $background={moduleBackgroundToCSS(moduleData?.bgColor)}
      $color={chooseLightOrDarkTextColor(moduleData?.bgColor)}
    >
      <Title>{moduleData?.title}</Title>
      <IframeWrapper>
        <iframe
          src={moduleData.url}
          title={moduleData?.title || 'Iframe module'}
          width="100%"
          height="600px"
          loading="lazy"
          sandbox="allow-scripts allow-same-origin"
          data-iframe=""
        />
      </IframeWrapper>
    </Container>
  );
};

export default IframeModule;
