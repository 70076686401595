import Head from 'next/head';
import { mapSiteLocaleToLangCountryCode } from '../../utils/locale';
import { PageFragment } from '../../wp-generated/types';

interface SetMetaRobotsContentProps {
  metaRobotsNoindex?: string | null;
  metaRobotsNofollow?: string | null;
}

const setMetaRobotsContent = ({
  metaRobotsNoindex,
  metaRobotsNofollow,
}: SetMetaRobotsContentProps) => {
  const index = metaRobotsNoindex || 'index';
  const follow = metaRobotsNofollow || 'follow';

  return `${index},${follow}, max-image-preview: large, max-snippet: -1, max-video-preview: -1`;
};

export const WordpressPagesMetadata = ({
  pageTitle,
  seo,
  locale,
}: {
  pageTitle: string;
  seo: PageFragment['seo'];
  locale?: string;
}) => {
  const { metaRobotsNoindex, metaRobotsNofollow } = seo || {};

  return (
    <Head>
      <title>{`${pageTitle} | Mynewsdesk`}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <meta
        name="robots"
        content={setMetaRobotsContent({ metaRobotsNoindex, metaRobotsNofollow })}
      />
      <meta name="description" content={seo?.metaDesc || ''} />
      <meta property="og:type" content={seo?.opengraphType || 'article'} />
      <meta property="og:title" content={seo?.opengraphTitle || pageTitle || ''} />
      <meta property="og:description" content={seo?.opengraphTitle || seo?.metaDesc || ''} />
      <meta property="og:url" content={seo?.opengraphUrl || ''} />
      <meta property="og:site_name" content="Mynewsdesk" />
      <meta property="og:locale" content={mapSiteLocaleToLangCountryCode(locale)} />
      <link rel="preconnect" href="https://cdn.cookielaw.org" />
      {seo?.schema?.raw && <script type="application/ld+json">{seo.schema.raw}</script>}
    </Head>
  );
};
