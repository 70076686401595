import React from 'react';
import styled, { css } from 'styled-components';
import { PageFragment } from '../../../wp-generated/types';

const Container = styled.section<{ $open?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--color-purple);
  padding: var(--spacing-medium);
  color: var(--color-white);
  gap: 5px;
  position: relative;

  ${({ $open }) =>
    !$open &&
    css`
      display: none;
    `}

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 400px) {
    flex-direction: row;
  }

  a {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-semi-bold);
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
`;

const Text = styled.div`
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
`;

const CloseIcon = styled.div`
  right: var(--spacing-medium);
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  > svg {
    width: 25px;
    height: 39px;
  }
`;

const Notice = ({
  notice,
  noticeIsOpen,
  setNoticeIsOpen,
}: {
  notice?: NonNullable<PageFragment['stickyModules']>['notice'];
  noticeIsOpen: boolean;
  setNoticeIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Container $open={noticeIsOpen}>
      <TextContainer>
        <Text>{notice?.title}</Text>
        {notice?.link?.url && (
          <a href={notice.link.url} target={notice?.link?.target || ''}>
            {notice?.link?.title}
          </a>
        )}
      </TextContainer>
      <CloseIcon onClick={() => setNoticeIsOpen(false)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 1000 1000"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="currentColor"
            d="M980 122C1008 94 1008 48 980 20 952-8 906-8 878 20L500 400 122 20C94-6 48-6 20 20S-8 94 20 122L400 500 20 878C-8 906-8 952 20 980 48 1008 94 1008 122 980L500 600 878 980C906 1008 952 1008 980 980 1008 952 1008 906 980 878L980 878 600 500 980 122Z"
          />
        </svg>
      </CloseIcon>
    </Container>
  );
};
export default Notice;
